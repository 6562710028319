import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods, CacheType } from '@commonstock/client/src/constants'
import { UserProfile } from './profile'
import { TagType, TagItemType } from '../types'
import { PagerOptions, createUsePagedFetch } from '@commonstock/client/src/react/createUsePagedFetch'
import { FeedOutput, pagerFeedItemsAfterUuid } from './feed'

export type Tag = {
  canonical_name: string
  display_name: string
  name: string
  tag_type: TagType
}

export const useGetTags = createUseFetch<Tag[]>({
  key: 'get-tags',
  path: `/tags`,
  cache: CacheType.Disk
})
type UpdateTagsParams = {
  meta: {
    uuid: string
  }
  json: {
    tags: string[]
    type: TagItemType
  }
}

export const useUpdateTagsAction = createUseFetchAction<Tag[], UpdateTagsParams>({
  key: 'update-tag',
  method: HttpMethods.Post,
  path: ({ meta: { uuid } }) => `/tagged/update/${uuid}`
})

export const useGetItemTags = createUseFetch<Tag[], { meta: { uuid: string } }>({
  key: 'get-item-tags',
  path: ({ meta: { uuid } }) => `/tagged/${uuid}`
})

export type GetTaggedProfilesParams = {
  meta: {
    tag: string
  }
  query?: {
    limit?: number
    item_type: TagItemType.PROFILE
  }
}

export type TaggedProfile = {
  item_uuid: string
  tag: string
  item_type: TagItemType.PROFILE
  payload: UserProfile
}
const PAGER_LIMIT = 15
const pagerOptions: PagerOptions<TaggedProfile[], any> = {
  getCursor: (page, resource) => {
    let list = resource?.success?.payload
    let last = list && list[list.length - 1]
    return { query: { limit: PAGER_LIMIT, offset: page * PAGER_LIMIT, after_uuid: last?.item_uuid } }
  },
  isTerminal: resource => !!(resource.success && resource.success.payload.length < PAGER_LIMIT)
}

export const useGetTaggedProfiles = createUsePagedFetch<TaggedProfile[], GetTaggedProfilesParams>(
  {
    key: 'get-tagged-profiles',
    path: ({ meta: { tag } }) => `/tagged/filter/${tag}`
  },
  pagerOptions
)

export type TaggedContentParams = {
  query: {
    tag: string
    content_types?: TagItemType
    before_uuid?: string
    after_uuid?: string
    limit?: number
  }
}

export const TAGGED_CONTENT_PATH = '/feed/v3/tagged-items'
export const useGetTaggedContent = createUsePagedFetch<FeedOutput, TaggedContentParams>(
  {
    key: 'get-tagged-content',
    path: TAGGED_CONTENT_PATH
  },
  pagerFeedItemsAfterUuid
)

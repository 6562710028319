import React from 'react'
import { CardFooterActions } from '../../components/styles'
import { CommentIcon } from '../comments/CommentIcon'
import ShareIcon from '../share/ShareIcon'
import { SpacerV } from '../../composing/Spacing'
import { FeedItem, isMemoFeedItem, isPostFeedItem, isTradeFeedItem } from '@commonstock/common/src/api/feed'
import ContentCardActions from 'src/scopes/content/ContentCardActions'
import { isLoggedIn } from '@commonstock/common/src/auth'
import RepostButton from 'src/components/repost/RepostButton'
import LikeList from 'src/components/LikeList'
import { useGetLikes } from '../likes/likeHooks'
import { destructContentFeedItem, getContentLikeType } from './utils'
import TradeCardActions from '../trade/TradeCardActions'
import { ContentLink } from './DetailContentWrapper'
import LikeIcon from '../likes/LikeIcon'
import { CardLayoutTypes, isUpvoteLayout } from '../feed/constants'

type Props = {
  feedItem: FeedItem
  detail?: boolean
  cardLayout: CardLayoutTypes
}

function ContentFooterActions({ feedItem, detail, cardLayout }: Props) {
  const { uuid } = feedItem
  const likes = useGetLikes(feedItem.uuid, getContentLikeType(feedItem), feedItem)
  let { content, user } = destructContentFeedItem(feedItem)

  if (!content || !user) return null
  return (
    <>
      <SpacerV />
      <CardFooterActions>
        {!isUpvoteLayout(cardLayout) && (
          <LikeIcon
            likes={content.likes}
            parentUuid={content.uuid}
            parentType={getContentLikeType(feedItem)}
            authorUuid={user.uuid}
          />
        )}

        {detail ? (
          <CommentIcon count={content.comments?.count} />
        ) : (
          <ContentLink feedItem={feedItem} cardLayout={cardLayout}>
            <CommentIcon count={content.comments?.count} />
          </ContentLink>
        )}

        {isLoggedIn() && (isMemoFeedItem(feedItem) || isPostFeedItem(feedItem)) ? (
          <RepostButton feedItem={feedItem} disabled={user.private} />
        ) : (
          <div />
        )}
        {detail && isLoggedIn() && likes && <LikeList likes={likes} uuid={uuid} />}
        <ShareIcon feedItem={feedItem} uuid={uuid} disabled={user.private} />

        {!isLoggedIn() ? null : isMemoFeedItem(feedItem) || isPostFeedItem(feedItem) ? (
          <ContentCardActions feedItem={feedItem} />
        ) : isTradeFeedItem(feedItem) ? (
          <TradeCardActions tradeFeedItem={feedItem} />
        ) : null}
      </CardFooterActions>
    </>
  )
}

export default ContentFooterActions

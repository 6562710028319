import React, { createContext, useMemo, useState, useContext, useEffect, ReactNode, useCallback } from 'react'
import { scaleLinear, ScaleLinear } from 'd3-scale'
import { Periods, ChartData, ChartDataValue } from '@commonstock/common/src/types'
import { DateTime } from 'luxon'
import { TradeFeedItem } from '@commonstock/common/src/api/feed'

const getTooltipText = (timestamp: number, period: Periods) => {
  // Using this to calc hour because luxon and Intl.DateTimeFormat doesnt work well on safari
  // Safari gets system language instead of system location to get format locale
  const dateTime = new Date(timestamp * 1000)
  let hours = dateTime.getHours() % 12 || 12
  let minutes = `${dateTime.getMinutes()}`.padStart(2, '0')
  const time = `${hours}:${minutes}${dateTime.getHours() < 12 ? 'AM' : 'PM'}`

  const date = DateTime.fromSeconds(timestamp).toLocaleString(DateTime.DATE_FULL)
  if (['1H', '1D'].includes(period)) return time
  if (period === '1W') return `${time}, ${date?.replace(/(, \d{4})/, '')}`
  return date
}

export type LineChartType = {
  chartData: ChartData
  color: string
  lineColor?: string
  height: number
  margin: Margins
  showReferenceLine?: 'never' | 'always' | 'hover'
  showTooltip?: boolean
  width: number
  children?: ReactNode
  strokeWidth?: number
  showBenchmark?: boolean
  dataKey: 'c' | 'v'
  referenceLineValue?: number
  bgColor?: string
  scrubbingLabel?: string
  scrubbingLabelBenchmark?: string
  tradesPending?: boolean
  trades?: TradeFeedItem[]
}

export type DataPoint = ChartDataValue & {
  b: number | null
  i: number
  tip: string
}
export type DataPointArray = DataPoint[]
export type Margins = { top: number; right: number; bottom: number; left: number }
type Coordinates = { [key: number]: { x: number; y: number; b: number | null } }

type ChartValue = LineChartType & {
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  data: DataPointArray
  coordinates: Coordinates
  isFlatLine: boolean
  getNearestCoordinate: (value: number, key: 't' | 'c' | 'v') => number
}
const LineChartContext = createContext<ChartValue | undefined>(undefined)
LineChartContext.displayName = 'LineChartContext'

export function LineChartProvider({ children, ...rest }: LineChartType) {
  const {
    chartData,
    height,
    margin,
    showReferenceLine,
    width,
    dataKey,
    showBenchmark,
    referenceLineValue,
    lineColor,
    trades,
    tradesPending
  } = rest

  const data: DataPointArray = useMemo(() => {
    return chartData.values.map((v, i) => ({
      ...v,
      i,
      b: chartData.benchmark ? chartData.benchmark[i]?.c : null,
      tip: getTooltipText(v.t, chartData.period_title) || ''
    }))
  }, [chartData.benchmark, chartData.period_title, chartData.values])

  // Horizontal scale (WIDTH)
  const xScale = useMemo(
    () =>
      scaleLinear()
        .domain([0, Math.max(chartData.max_points, data.length)])
        .range([0, width - margin.left - margin.right]),
    [chartData.max_points, data.length, margin, width]
  )

  // Vertical scale (HEIGHT)
  const yScale = useMemo(() => {
    let allValues = data.map(d => d[dataKey])
    if (dataKey === 'c' && showBenchmark && chartData.benchmark) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      allValues = [...allValues, ...data.map(d => d.b!)]
    }
    if (referenceLineValue !== undefined && showReferenceLine !== 'never') allValues.push(referenceLineValue)
    return scaleLinear()
      .domain([Math.min(...allValues), Math.max(...allValues)])
      .range([height - margin.top - margin.bottom, 0])
  }, [chartData, data, dataKey, height, margin, referenceLineValue, showBenchmark, showReferenceLine])

  const [coordinates, setCoordinates] = useState<Coordinates>({})
  useEffect(() => {
    let coordinates: Coordinates = {}
    data.forEach((d, i) => {
      const x = xScale(d.i) + margin.left
      const y = yScale(d[dataKey]) + margin.top
      const b = d.b && yScale(d.b) + margin.top
      coordinates[i] = { x, y, b }
    })
    setCoordinates(coordinates)
  }, [data, dataKey, margin.left, margin.top, xScale, yScale])

  const isFlatLine = useMemo(() => data.filter(v => !!v[dataKey]).length === 0, [data, dataKey])

  const getNearestCoordinate = useCallback(
    (value: number, key: 't' | 'c' | 'v') => {
      const diffArr = data.map(x => Math.abs(value - x[key]))
      const minNumber = Math.min(...diffArr)
      const index = diffArr.findIndex(x => x === minNumber)
      return index
    },
    [data]
  )

  const value: ChartValue = useMemo(
    () => ({
      ...rest,
      lineColor: lineColor || rest.color,
      xScale,
      yScale,
      data,
      coordinates,
      isFlatLine,
      getNearestCoordinate,
      trades,
      tradesPending
    }),
    [coordinates, data, getNearestCoordinate, isFlatLine, lineColor, rest, trades, tradesPending, xScale, yScale]
  )

  return <LineChartContext.Provider value={value}>{children}</LineChartContext.Provider>
}

export function useLineChart() {
  const context = useContext(LineChartContext)
  if (!context) throw 'No LineChart parent found'
  return context
}

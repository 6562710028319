import React from 'react'
import { DetailCard, PaneColumn, PanelGrid } from '../../components/styles'
import { BrandSpinner } from '../../components/Spinner'
import { Spacing } from '../../composing/Spacing'
import { useGetMemo } from '@commonstock/common/src/api/memo'
import { ParentTypes, useGetComment } from '@commonstock/common/src/api/comment'
import { MemoContent } from './MemoCard'
import { useAuth } from '../auth/AuthContext'
import { cx } from '@linaria/core'
import RootComments from '../comments/RootComments'
import CommentItem from '../comments/CommentItem'
import { useTrackMemoDetailImpression } from '../analytics/events.v1'
import { MemoFeedItem } from '@commonstock/common/src/api/feed'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { useRouter } from 'next/router'
import NotFound from '../../components/NotFound'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { DetailContentWrapper } from '../content/DetailContentWrapper'
import RelatedContent from 'src/scopes/discover/RelatedContent'
import { CardLayoutTypes } from 'src/scopes/feed/constants'
import PrivateContentCard from 'src/scopes/profile/PrivateContentCard'

type Params = { memoId: string; commentId?: string }
type Props = {
  isModal?: boolean
  memoFeedItem?: MemoFeedItem
  privateProfile?: HydratedUserProfile
  uuid?: string
  cardLayout: CardLayoutTypes
}
function MemoDetail({ isModal, memoFeedItem, privateProfile, uuid, cardLayout }: Props) {
  const router = useRouter()
  const { commentId, memoId } = router.query as Params
  const { isModerator } = useAuth()

  const memoUuid = uuid || memoId || memoFeedItem?.uuid || ''

  const [fetchMemo, fail] = useGetMemo(
    { meta: { uuid: memoUuid } },
    { paused: (!isLoggedIn() && !!privateProfile) || !memoUuid }
  )

  const [fetchComment, failComment] = useGetComment({ meta: { uuid: commentId || '' } }, { paused: !commentId })
  memoFeedItem = fetchMemo || memoFeedItem
  const rootUserUuid = memoFeedItem?.user_uuid || ''

  useTrackMemoDetailImpression(memoFeedItem)

  if ((!isModal && (fail || failComment)) || (!isLoggedIn() && privateProfile)) {
    return (
      <PanelGrid>
        <PaneColumn>
          {privateProfile ? (
            <PrivateContentCard
              user={privateProfile}
              title="This memo is from a private account."
              subtitle={`Follow ${privateProfile.name} on Commonstock to view this memo.`}
            />
          ) : (
            <NotFound />
          )}
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }
  if (isModal && fail) {
    return (
      <DetailCard className={cx(isModal && 'modal')}>
        <NotFound />
      </DetailCard>
    )
  }
  if (!memoFeedItem || (commentId && !fetchComment)) {
    if (isModal) {
      return (
        <DetailCard className={cx(isModal && 'modal', 'loading')}>
          <BrandSpinner />
        </DetailCard>
      )
    }
    return (
      <PanelGrid>
        <PaneColumn>
          <DetailCard className={cx(isModal && 'modal', 'loading')}>
            <BrandSpinner />
          </DetailCard>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }
  if (isModerator && fetchComment?.archived_at) {
    return (
      <PanelGrid>
        <PaneColumn>
          <Spacing padding={[0, 1, 1]}>
            <DetailCard>
              <CommentItem
                comment={fetchComment}
                rootType={ParentTypes.MEMO_PLUS}
                rootUuid={memoUuid}
                rootUserUuid={rootUserUuid}
                cardLayout={cardLayout}
              />
            </DetailCard>
          </Spacing>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }

  if (!isModal) {
    return (
      <PanelGrid>
        <PaneColumn>
          <MemoPageContent
            memoFeedItem={memoFeedItem}
            memoUuid={memoUuid}
            rootUserUuid={rootUserUuid}
            cardLayout={cardLayout}
          />
        </PaneColumn>
        <PaneColumn>
          <RelatedContent feedItem={memoFeedItem} />
        </PaneColumn>
      </PanelGrid>
    )
  }

  return (
    <MemoPageContent
      isModal
      memoFeedItem={memoFeedItem}
      memoUuid={memoUuid}
      rootUserUuid={rootUserUuid}
      cardLayout={cardLayout}
    />
  )
}

type MemoMainContentProps = {
  isModal?: boolean
  memoFeedItem: MemoFeedItem
  memoUuid: string
  rootUserUuid: string
  cardLayout: CardLayoutTypes
}
function MemoPageContent({ isModal, memoFeedItem, memoUuid, rootUserUuid, cardLayout }: MemoMainContentProps) {
  const initialComments = memoFeedItem?.resources.memos[memoFeedItem.uuid]?.comments

  return (
    <DetailContentWrapper feedItem={memoFeedItem} isModal={isModal} cardLayout={cardLayout}>
      <MemoContent memoFeedItem={memoFeedItem} detail cardLayout={cardLayout} />
      <RootComments
        initialComments={initialComments}
        uuid={memoUuid}
        type={ParentTypes.MEMO_PLUS}
        isModal={!!isModal}
        rootUserUuid={rootUserUuid}
        cardLayout={cardLayout}
      />
    </DetailContentWrapper>
  )
}

export default MemoDetail
